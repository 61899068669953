import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IReduxState } from '../../modules';
import { selectors } from '../../modules/chat';
import { sendChatMessage } from '../../modules/chat/chat.actionCreators';
import Chat from './chat.component';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendChatMessage: (message: string) => {
    dispatch(sendChatMessage(message));
  }
});

const mapStateToProps = (state: IReduxState) => {
  return {
    messages: selectors.messages(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);


