import { createAction } from 'typesafe-actions';
import { LOG_EVENT, LOG_PAGE } from './actions';

export const logEvent = createAction(LOG_EVENT)<{
  category: string,
  action: string,
  extra?: any,
}>();

export const logPage = createAction(LOG_PAGE)<string>();
