import { createAction } from 'typesafe-actions';

import { SET_CHART_STATS, MONITOR_STATS, SET_SERVER_INFO, FETCH_STATS } from './actions';
import { ServerInfo } from './types';

export const monitorServerStats = createAction(MONITOR_STATS)();
export const fetchServerStats = createAction(FETCH_STATS)();
export const setServerInfo = createAction(SET_SERVER_INFO)<ServerInfo>();

export const setChartStats = createAction(SET_CHART_STATS)<{
  name: string,
  entityCount: number,
  fps: number,
  memory: number,
  networkIn: number,
  networkOut: number,
}>();
