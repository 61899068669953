import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IReduxState } from '../../modules';
import Console from './console.component';
import { selectors } from '../../modules/console';
import { sendConsoleMessage } from '../../modules/console/console.actionCreators';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendConsoleMessage: (message: string) => {
    dispatch(sendConsoleMessage(message));
  }
});

const mapStateToProps = (state: IReduxState) => {
  return {
    logs: selectors.logs(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Console);


