import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Users, Cpu, HardDrive, Box, Clock, ArrowUp, ArrowDown, Watch } from 'react-feather';

import DashboardCard from './DashboardCard';
import PlayersTable from '../../components/players-table';
import Layout from '../layout';
import LineChart from '../../components/linechart';
import { ServerInfo } from '../../modules/serverStats/types';
import Player from '../../models/Player';
import { Server } from '../../modules/servers/servers.types';
import PlayersCard from '../../components/players-card';

type ChartData = { name: string; key: number }[];

export interface Props {
  currentServer?: Server,
  serverInfo: ServerInfo;
  entityData: ChartData;
  framerateData: ChartData;
  networkIn: ChartData;
  networkOut: ChartData;
  memoryData: ChartData;
  entityCount: string;
  formattedMemory: string;
  fps: string;
  formattedUptime: string;
  players: Player[];
  formattedNetworkIn: string;
  formattedNetworkOut: string;
}

export type CombinedProps = Props & RouteChildrenProps;

export default class Dashboard extends React.Component<CombinedProps> {
  render() {
    const {
      currentServer,
      entityData,
      framerateData,
      memoryData,
      entityCount,
      formattedMemory,
      fps,
      serverInfo,
      formattedUptime,
      players,
      networkOut,
      networkIn,
      formattedNetworkOut,
      formattedNetworkIn,
    } = this.props;
    return (
      <Layout>
        <div className="page-header">
          <div className='mr-auto col-auto mb-3 mb-sm-0 pl-0'>
            <h1 className="page-title p-0">{currentServer?.name}</h1>
            <p className='mb-0 text-muted'>{serverInfo.hostName || 'Loading...'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-12 d-flex flex-column">
            <PlayersCard
              maxPlayers={serverInfo.maxPlayers}
              players={serverInfo.playerCount}
              connectingPlayers={serverInfo.playersConnecting}
              queuedPlayers={serverInfo.playersQueued}
            />
            {/*<DashboardCard*/}
            {/*  icon={Users}*/}
            {/*  value={`${serverInfo.playerCount} / ${serverInfo.maxPlayers}`}*/}
            {/*  subTitle="Players"*/}
            {/*  color="green"*/}
            {/*/>*/}
            <DashboardCard
              icon={Watch}
              value={formattedUptime}
              subTitle="Uptime"
              color="orange"
            />
            <DashboardCard
              icon={Cpu}
              value={fps}
              subTitle="Frames per Second"
              color="blue"
              className="fps-card"
              data={framerateData}
              chart={<LineChart fixedHeight={false} />}
            />
          </div>
          <div className="col-lg-8 col-md-12 d-flex flex-column">
            <DashboardCard
              flexFill
              icon={HardDrive}
              value={formattedMemory}
              subTitle="Memory Usage"
              color="red"
              data={memoryData}
              chart={<LineChart />}
            />
            <DashboardCard
              flexFill
              icon={Box}
              value={entityCount}
              subTitle="Entities"
              color="yellow"
              data={entityData}
              chart={<LineChart />}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <DashboardCard
              icon={ArrowUp}
              value={formattedNetworkOut}
              subTitle="Network Out"
              color="pink"
              data={networkOut}
              chart={<LineChart />}
            />
          </div>
          <div className='col-lg-6 col-md-12'>
            <DashboardCard
              icon={ArrowDown}
              value={formattedNetworkIn}
              subTitle="Network In"
              color="purple"
              data={networkIn}
              chart={<LineChart />}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <PlayersTable players={players} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
