import React, { createRef } from 'react';

import { ChatMessage } from '../../modules/chat/chat.types';
import Layout from '../layout';
import ChatMessageItem from './chatMessageItem';

export interface Props {
  messages: ChatMessage[];
  sendChatMessage: (message: string) => void;
}

export interface State {
  message: string;
}

class Chat extends React.Component<Props, State> {
  shouldScroll = false;
  chatView = createRef<HTMLDivElement>();
  state = {
    message: '',
  };

  constructor(props: Props) {
    super(props);
    this.sendMessage = this.sendMessage.bind(this);
  }

  componentDidMount() {
    if (this.chatView.current) {
      this.chatView.current.scrollTop = this.chatView.current.scrollHeight;
    }
  }

  UNSAFE_componentWillUpdate() {
    if (!this.chatView.current) {
      return;
    }
    this.shouldScroll =
      this.chatView.current.scrollTop + this.chatView.current.clientHeight ===
      this.chatView.current.scrollHeight;
  }

  componentDidUpdate() {
    if (!this.chatView.current) {
      return;
    }

    if (this.shouldScroll) {
      this.chatView.current.scrollTop = this.chatView.current.scrollHeight;
    }
  }

  render() {
    const { messages } = this.props;
    const { message } = this.state;
    return (
      <Layout useFlex>
        <div className="row d-flex flex-column flex-fill">
          <div className="col-12 d-flex flex-column flex-fill">
            <div className="card mb-2 flex-fill">
              <div className="card-header">
                <h3 className="card-title">Chat</h3>
              </div>
              <div
                className="card-body o-auto pt-4 pb-4 pl-5 pr-5"
                style={{flex: "1 1 auto",
                  overflowY: "auto",
                  height: "0px"}}
                ref={this.chatView}
              >
                <ul className="list-unstyled list-separated">
                  {messages.map((chatItem, index) => {
                    return (
                      <ChatMessageItem
                        steamID={chatItem.steamId}
                        time={chatItem.time}
                        key={index}
                        name={chatItem.name}
                        color={chatItem.color}
                        message={chatItem.message}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
            <form onSubmit={this.sendMessage}>
              <div className="form-group">
                <div className="row gutters-xs">
                  <div className="col">
                    <input
                      type="text"
                      name="message"
                      required
                      onChange={e => this.setState({ message: e.target.value })}
                      className="form-control"
                      placeholder="Type your message..."
                      value={message}
                    />
                  </div>
                  <span className="col-auto">
                    <button className="btn btn-danger" type="submit">
                      <i className="fe fe-send" />
                    </button>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
  sendMessage(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    const { sendChatMessage } = this.props;
    const { message } = this.state;
    sendChatMessage(message);
    this.setState({ message: '' });
  }
}

export default Chat;
