import { takeLatest, delay, select, put} from 'redux-saga/effects';
import { selectors } from '../../session';
import { fetchServerStats, monitorServerStats } from '../actionsCreators';

/**
 * Begin the server stats monitor - it will wait until we're connected to
 * send the message once every 5 seconds
 */
export function* beginMonitorServerStats() {
  yield put(fetchServerStats());
  while (true) {
    yield delay(5000);
    const isConnected: boolean = yield select(selectors.isConnected);
    if (!isConnected) {
      return;
    }
    yield put(fetchServerStats());
  }
}

export default function* listenSocketMonitor() {
  yield takeLatest(monitorServerStats, beginMonitorServerStats);
}
