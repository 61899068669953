import React from 'react';
import classNames from 'classnames';
import { Activity, Clock, Users } from 'react-feather';

export interface Props {
  maxPlayers: number;
  players: number;
  connectingPlayers: number;
  queuedPlayers: number;
  className?: string;
}

const PlayersCard: React.FunctionComponent<Props> = ({ className, maxPlayers, connectingPlayers, queuedPlayers, players }) => (
  <div className={classNames('card', className)}>
    <div className="card-body p-0 d-flex flex-column">
      <div className="p-3 text-center d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Users size={16} className={`mr-1 text-green`} />
          <div className={`text-green rm-0`}>Players</div>
        </div>
        <div className={`m-0 text-green`}>{players} / {maxPlayers}</div>
      </div>
      <div className="px-3 pb-1 pt-0 text-center d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className={`text-green rm-0 text-small`}>Queued</div>
        </div>
        <div className={`m-0 text-green`}>{queuedPlayers}</div>
      </div>
      <div className="px-3 pb-3 pt-0 text-center d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className={`text-green rm-0 text-small`}>Connecting</div>
        </div>
        <div className={`m-0 text-green`}>{connectingPlayers}</div>
      </div>
    </div>
  </div>
);

export default PlayersCard;
