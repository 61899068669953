import { IReducerState } from './types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actionsCreators';
import { List } from 'immutable';

const initialState: IReducerState = {
  hostName: '',
  playerCount: 0,
  playersConnecting: 0,
  playersQueued: 0,
  memory: List([]),
  framerate: List([]),
  entityCount: List([]),
  networkIn: List([]),
  networkOut: List([]),
  maxPlayers: 0,
  uptime: 0,
};

const reducer = (state = initialState, action: ActionType<typeof actions>) => {
  switch (action.type) {
    // do reducer stuff
    case getType(actions.setChartStats):
      const newEntityList = state.entityCount.size > 15 ? state.entityCount.shift() : state.entityCount;
      const newFramerateList = state.framerate.size > 15 ? state.framerate.shift() : state.framerate;
      const newMemoryList = state.memory.size > 15 ? state.memory.shift() : state.memory;
      const newNetworkInList = state.networkIn.size > 15 ? state.networkIn.shift() : state.networkIn;
      const newNetworkOutList = state.networkOut.size > 15 ? state.networkOut.shift() : state.networkOut;
      return {
        ...state,
        entityCount: newEntityList.push({
          name: action.payload.name,
          count: action.payload.entityCount,
        }),
        framerate: newFramerateList.push({
          name: action.payload.name,
          fps: action.payload.fps,
        }),
        memory: newMemoryList.push({
          name: action.payload.name,
          memory: action.payload.memory
        }),
        networkIn: newNetworkInList.push({
          name: action.payload.name,
          in: action.payload.networkIn
        }),
        networkOut: newNetworkOutList.push({
          name: action.payload.name,
          out: action.payload.networkOut
        })
      };
    case getType(actions.setServerInfo):
      return {
        ...state,
        playersQueued: action.payload.playersQueued,
        playersConnecting: action.payload.playersConnecting,
        hostName: action.payload.hostName,
        playerCount: action.payload.playerCount,
        maxPlayers: action.payload.maxPlayers,
        uptime: action.payload.uptime,
      };
    default: return state;
  }
};

export default reducer;
