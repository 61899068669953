import { List } from 'immutable';
import { ChatMessage, IReducerState } from './chat.types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './chat.actionCreators';

const initialState: IReducerState = {
  chatMessages: List<ChatMessage>(),
};

const reducer = (state = initialState, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.appendChatMessage):
      return {
        ...state,
        chatMessages: state.chatMessages.push(action.payload),
      };
    case getType(actions.setChatMessages):
      return {
        ...state,
        chatMessages: List(action.payload),
      };
    default: return state;
  }
};

export default reducer;
