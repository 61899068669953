import { connect } from 'react-redux';
import { IReduxState } from '../../modules';
import { Dispatch } from 'redux';
import Settings from './index';
import { removeServer, updateServer } from '../../modules/servers/servers.actionCreators';
import { selectors } from '../../modules/servers';
import { stopSocketMonitor } from '../../modules/session/actionsCreators';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeServer: (id: string) => {
    dispatch(removeServer(id));
    dispatch(stopSocketMonitor());
  },
  updateServerName(name: string) {
    dispatch(updateServer(name));
  }
});

const mapStateToProps = (state: IReduxState) => {
  return {
    currentServer: selectors.currentServer(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);


