import React, { useState } from 'react';
import { ListRowProps } from 'react-virtualized/dist/es/List';
import { RustItem } from '../index';

export interface Props {
  data: RustItem;
  giveItem: (item: RustItem, amount: number) => void;
}

export type CombinedProps = Props & ListRowProps;

const ItemCard = ({ index, style, key, data, giveItem }: CombinedProps) => {
  const [amount, setAmount] = useState('');

  const handleClick = () => {
    setAmount('');
    giveItem(data, parseInt(amount));
  };

  return (
    <div key={key} style={style} className="pt-2">
      <div className="d-flex card h-100 w-100 mb-0">
        <div className="card-body p-2 d-flex align-items-center">
          <img alt={data.item_name} style={{ width: 40, height: 40 }} src={data.img_url} />
          <div className="ml-3 w-100">
            <h4 className="text-red mb-0">{data.item_name}</h4>
            <p className="mb-0 text-code">{data.item_short_code}</p>
          </div>
          <div className="d-flex">
            <input
              type="number"
              className="form-control flex-fill mr-2 ml-2"
              placeholder="1"
              value={amount}
              onChange={e => setAmount(e.target.value)}
            />
            <button disabled={!amount} onClick={handleClick} className="btn btn-green flex-fill d-flex align-items-center justify-content-center">
              <i className="fe fe-send" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
