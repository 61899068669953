import { takeLatest, call } from 'redux-saga/effects';
import ReactGA from 'react-ga';

import { logPage } from '../actionsCreators';

export function* performLogEvent(action: ReturnType<typeof logPage>) {
  yield call(ReactGA.set, { page: action.payload });
  yield call(ReactGA.pageview, action.payload);
}

export default function* listenForAction() {
  yield takeLatest(logPage, performLogEvent);
}

