import { MiddlewareAPI } from 'redux';
import JSONBig from "json-bigint";
import moment from 'moment';
import requestTypes from '../../../constants/requestIdentifier';
import { setChartStats, setServerInfo } from '../../serverStats/actionsCreators';
import Player from '../../../models/Player';
import { setBannedPlayers, setPlayers } from '../../players/players.actionCreators';
import { PlayerBan } from '../../players/types';
import { actions as toastrActions } from 'react-redux-toastr';
import { appendConsoleLog, setConsoleLogs } from '../../console/console.actionCreators';
import { ChatMessage } from '../../chat/chat.types';
import { appendChatMessage, setChatMessages } from '../../chat/chat.actionCreators';
import { SocketResponse } from '../../../models/socketResponses/SocketResponse';
import parseJsonResponse from '../../../utils/parseJsonResponse';

export function handleMessage(store: MiddlewareAPI, message: SocketResponse<any>) {
  const time = moment().format("hh:mm:ss a");

  switch (message.Identifier) {
    case requestTypes.GET_SERVER_INFO:
      console.log(message.Message);
      store.dispatch(setServerInfo({
        hostName: message.Message.Hostname,
        playerCount: message.Message.Players,
        maxPlayers: message.Message.MaxPlayers,
        uptime: message.Message.Uptime,
        playersQueued: message.Message.Queued,
        playersConnecting: message.Message.Joining,
      }));
      store.dispatch(
        setChartStats({
          name: time,
          entityCount: parseInt(message.Message.EntityCount),
          fps: parseInt(message.Message.Framerate),
          memory: parseInt(message.Message.Memory),
          networkIn: message.Message.NetworkIn,
          networkOut: message.Message.NetworkOut,
        })
      );
      break;
    case requestTypes.GET_PLAYERS:
      const mappedPlayers = message.Message.map((player: any) => new Player(
        player.DisplayName,
        player.Address,
        player.SteamID,
        player.Health,
        player.ConnectedSeconds,
        player.Ping,
      ));
      store.dispatch(setPlayers(mappedPlayers));
      break;
    case requestTypes.GET_BANS:
      const bans = message
        .Message
        .map((ban: any): PlayerBan => ({
          steamID: ban.steamid.toString(),
          username: ban.username,
          reason: ban.notes,
        }));
      store.dispatch(setBannedPlayers(bans));
      break;
    case requestTypes.UNBAN_PLAYER:
      // This is inferred to be a success
      store.dispatch(toastrActions.add({
        type: 'success',
        title: 'Played Unbanned',
        message: 'Successfully unbanned player.',
      }));
      break;
    case requestTypes.BAN_PLAYER:
      // This is inferred to be a success
      store.dispatch(toastrActions.add({
        type: 'success',
        title: 'Player Banned',
        message: 'Successfully banned player.',
      }));
      break;
    case requestTypes.KICK_PLAYER:
      // This is inferred to be a success
      store.dispatch(toastrActions.add({
        type: 'success',
        title: 'Player Kicked',
        message: 'Successfully kicked player.',
      }));
      break;
    case requestTypes.GET_CONSOLE_LOGS:
      const messages = message
        .Message
        .map((message: any) => message.Message)
        .filter((message: string) => !message.startsWith('[CHAT]'));
      store.dispatch(setConsoleLogs(messages));
      break;
    case requestTypes.GET_CHAT:
      const mappedItems: ChatMessage[] = message
        .Message
        .map((message: any) => ({
          steamId: message.UserId,
          time: message.Time,
          name: message.Username,
          color: message.Color,
          message: message.Message,
        }));
      store.dispatch(setChatMessages(mappedItems));
      break;
    case requestTypes.RECEIVE_CHAT_MESSAGE:
      store.dispatch(appendChatMessage({
        steamId: message.Message.UserId,
        time: message.Message.Time,
        name: message.Message.Username,
        color: message.Message.Color,
        message: message.Message.Message,
      }));
      break;
    case requestTypes.GIVE_PLAYER_ITEM:
      store.dispatch(toastrActions.add({
        type: 'success',
        title: 'Item Given',
        message: 'Item successfully given.',
      }));
      break;
    case requestTypes.RECEIVE_CONSOLE_MESSAGE:
    case requestTypes.SEND_CONSOLE_MESSAGE:
      if ((typeof message.Message === "string") && message.Message.startsWith('[CHAT]')) {
        return;
      }
      store.dispatch(appendConsoleLog(message.Message.toString()));
      break;
    case requestTypes.RESTART_SERVER:
      if (message.Message === 'Restarting in 300 seconds') {
        store.dispatch(toastrActions.add({
          type: 'success',
          title: 'Successfully Restarted',
          message: 'The countdown has began and the server will restart in 5 minutes.',
        }));
      }
      break;
    default:
      break;
  }
}

export default (store: MiddlewareAPI) => (event: MessageEvent) => {
  const parsedMessage = parseJsonResponse(event);
  handleMessage(store, parsedMessage)
}
