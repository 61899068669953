import { takeLatest, delay, select, put} from 'redux-saga/effects';
import { fetchBans, fetchPlayers, monitorPlayers } from '../players.actionCreators';
import { selectors } from '../../session';

export function* beginMonitorPlayers() {
  yield put(fetchPlayers());
  yield put(fetchBans());

  while (true) {
    yield delay(5000);
    const isConnected: boolean = yield select(selectors.isConnected);
    if (!isConnected) {
      return;
    }
    yield put(fetchPlayers());
    yield put(fetchBans());
  }
}

export default function* listenSocketMonitor() {
  yield takeLatest(monitorPlayers, beginMonitorPlayers);
}
