import { eventChannel, EventChannel, END } from 'redux-saga';
import parseJsonResponse from '../../../utils/parseJsonResponse';
import requestTypes from '../../../constants/requestIdentifier';
import GetHeaderImage from '../../../models/socketMessages/GetHeaderImage';

export default function createSocketChannel(socket: WebSocket) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return eventChannel(emit => {

    // websocket handlers
    socket.addEventListener('message', (event: MessageEvent) => {
      const parsedMessage = parseJsonResponse(event);
      if (parsedMessage.Identifier !== requestTypes.GET_HEADER_IMAGE) {
        return;
      }
      const parsedHeaderImage = parsedMessage.Message.match('"(.*)"')[1];
      socket.close(1000);
      emit(parsedHeaderImage);
      emit(END);
    });

    socket.onerror = (event) => {
      const error = new Error('Failed to connect to server');
      emit(error);
      emit(END);
    };

    socket.onopen = () => {
      // send message to get server header image
      const message = new GetHeaderImage();
      socket.send(message.toString());
    };

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    return () => {
      socket.close();
    };

  })
}
