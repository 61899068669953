import React, { ChangeEvent } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { AutoSizer, List } from 'react-virtualized';
import ItemCard from './ItemCard';

import data from '../../assets/items.json';

export interface Props {
  isOpen: boolean;
  toggle: () => void;
  giveItem: (item: RustItem, amount: number) => void;
}

export interface State {
  searchString: string;
  items: any;
}

export interface RustItem {
  item_name: string;
  item_short_code: string;
  img_url: string;
}

class GiveItemModal extends React.Component<Props, State> {
  state = {
    searchString: '',
    items: data,
  };

  constructor(props: Props) {
    super(props);
    this.updateSearchString = this.updateSearchString.bind(this);
    this.close = this.close.bind(this);
  }

  updateSearchString(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.value) {
      this.setState({ items: data });
    }

    const filteredItems = data.filter(item => {
      return item.item_name
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    this.setState({ items: filteredItems });
  }

  close() {
    const { toggle } = this.props;
    this.setState({
      searchString: '',
      items: data,
    });
    toggle();
  }

  render() {
    const { isOpen, giveItem } = this.props;
    const { items } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={this.close}>
        <ModalBody>
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Search for an item..."
            onChange={this.updateSearchString}
            name="reason"
            min={1}
            max={1000}
          />
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                style={{ outline: 'none' }}
                width={width}
                height={300}
                overscanRowCount={10}
                rowHeight={80}
                rowRenderer={props => (
                  <ItemCard
                    giveItem={giveItem}
                    data={items[props.index]}
                    {...props}
                  />
                )}
                rowCount={items.length}
              />
            )}
          </AutoSizer>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.close}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default GiveItemModal;
