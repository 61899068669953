import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import colors from '../../utils/colors';

const ChatMessageItem = ({
  steamID,
  name,
  message,
  color,
  time,
}: {
  name: string;
  message: string;
  color: string;
  time: number;
  steamID: string;
}) => {
  return (
    <li className="list-separated-item">
      <div className="d-flex flex-column">
        <div>
          <p className="text-inherit mb-1">{message}</p>
        </div>
        <small className="d-block item-except text-sm text-muted h-1x">
          <span>
            <Link style={{ color: name === 'SERVER' ? colors.red : color }} to={`/players/${steamID}`}>{name}</Link>
          </span>{' '}
          - {moment.unix(time).format('DD/MM/YY HH:mm')}
        </small>
      </div>
    </li>
  );
};

export default ChatMessageItem;
