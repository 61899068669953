import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { IReduxState } from '../../modules';
import ServerList from './index';
import { selectors, actionCreators } from '../../modules/servers';
import { actionCreators as sessionActionCreators } from '../../modules/session';
import { ADD_SERVER_MODAL, MAILING_LIST_MODAL } from '../../constants/modalIdentifiers';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addServer: (name: string, ip: string, port: string, password: string) => {
    dispatch(actionCreators.addServer.request({
      name,
      ip,
      port,
      password
    }));
  },
  connectToServer: (id: string) => {
    dispatch(actionCreators.setCurrentServer(id));
    dispatch(sessionActionCreators.beginSocketMonitor.request(id));
  },
  showAddServerModal: () => {
    dispatch(show(ADD_SERVER_MODAL));
  },
  showMailingListModal: () => {
    dispatch(show(MAILING_LIST_MODAL));
  },
});

const mapStateToProps = (state: IReduxState) => {
  return {
    servers: selectors.servers(state),
    isAdding: selectors.isAdding(state),
    addError: selectors.addingError(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServerList);


