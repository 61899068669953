import { takeLatest, put } from 'redux-saga/effects';
import { LocationChangeAction } from 'connected-react-router';
import { actionCreators } from '../../analytics';

export function* clearServerCredentials(action: LocationChangeAction) {
  yield put(actionCreators.logPage(action.payload.location.pathname));
}

export default function* listenSocketMonitor() {
  yield takeLatest('@@router/LOCATION_CHANGE', clearServerCredentials);
}

