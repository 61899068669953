import SocketMessage from './SocketMessage';
import requestTypes from '../../constants/requestIdentifier';

export default class SendChatMessage extends SocketMessage {
  identifier = requestTypes.SEND_CHAT_MESSAGE;
  message: string;

  constructor(message: string) {
    super();
    this.message = `say ${message}`;
  }
}
