import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IReduxState } from '../../modules';
import { selectors } from '../../modules/players';
import Players from './players.component';
import { RouteChildrenProps } from 'react-router';
import { unbanPlayer } from '../../modules/players/players.actionCreators';

export interface Props {

}

export type CombinedProps = Props & RouteChildrenProps;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  unbanPlayer: (steamId: string) => {
    return dispatch(unbanPlayer(steamId));
  }
});

const mapStateToProps = (state: IReduxState, ownProps: any) => {
  return {
    ...ownProps,
    players: selectors.players(state),
    bannedPlayers: selectors.bannedPlayers(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Players);


