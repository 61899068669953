import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const TabMenuItem = ({ title, onClick, active, ...rest }) => (
  <li className="nav-item" onClick={onClick}>
    <div className={classNames('nav-link', { active })}>
      {title}
    </div>
  </li>
);

TabMenuItem.defaultProps = {
  active: false,
};

TabMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

export default TabMenuItem;
