import React from 'react';
import { Wifi } from 'react-feather';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { stopSocketMonitor } from '../../modules/session/actionsCreators';
import { IReduxState } from '../../modules';
import { selectors } from '../../modules/session';
import colors from '../../utils/colors';

export interface Props {
  isConnected: boolean;
}

class ConnectionNavItem extends React.Component<Props> {
  getColor() {
    const { isConnected } = this.props;
    return isConnected ? colors.green : colors.yellow;
  }

  render() {
    const { isConnected } = this.props;
    return (
      <li className="nav-item ml-lg-auto bg-dark-darkest" style={{ color: this.getColor() }}>
        <div style={{ padding: "0.75rem"}}>
          <Wifi size={18} className='mr-2' style={{ color: this.getColor() }} />
          {isConnected ? 'Connected' : 'Connecting...'}
        </div>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  disconnect: () => dispatch(stopSocketMonitor()),
});

const mapStateToProps = (state: IReduxState) => {
  return {
    isConnected: selectors.isConnected(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionNavItem);
