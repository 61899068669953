import React from 'react';
import PropTypes from 'prop-types';

import TabMenuItem from '../tab-menu-item/index';

export default class TabContainer extends React.Component {
  render() {
    const { menuItems, children, style = {} } = this.props;
    return (
      <div className="card flex-fill" style={style}>
        <div className="d-flex flex-column card-body pb-4 pl-4 pr-4 pt-0">
          <ul className="nav nav-tabs">{menuItems}</ul>
          {children}
        </div>
      </div>
    );
  }
}

TabContainer.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.instanceOf(TabMenuItem)),
};
