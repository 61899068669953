import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';
import { PersistGate } from 'redux-persist/integration/react';

import '../node_modules/bootstrap/js/dist/index';

import App from './features/app/app.container';
import { rootStore, history, persistor } from './modules';
import './styles.scss';

ReactDOM.render(
  <Provider store={rootStore}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
      <ReduxToastr
        timeOut={4000}
        preventDuplicates
        position="bottom-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        closeOnToastrClick
      />
    </PersistGate>
  </Provider>,
  document.getElementById('app')
);
