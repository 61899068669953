const colors: {
  [key: string]: string;
} = {
  green: '#85C640',
  red: '#CE422B',
  yellow: '#C6C040',
  blue: '#40B8C6',
  purple: '#9140C6',
  pink: '#C64082',
};

export default colors;
