import { Middleware, MiddlewareAPI } from 'redux';
import { getType } from 'typesafe-actions';
import { fetchConsoleLogs, sendConsoleMessage } from '../../console/console.actionCreators';
import {
  beginSocketMonitor,
  restartServer,
  stopSocketMonitor,
} from '../actionsCreators';
import SendConsoleCommand from '../../../models/socketMessages/SendConsoleCommand';
import {
  banPlayer, fetchBans, fetchPlayers,
  giveItem,
  kickPlayer, monitorPlayers,
  unbanPlayer,
} from '../../players/players.actionCreators';
import { fetchChat, sendChatMessage } from '../../chat/chat.actionCreators';
import SendChatMessage from '../../../models/socketMessages/SendChatMessage';
import UnbanPlayer from '../../../models/socketMessages/UnbanPlayer';
import BanPlayer from '../../../models/socketMessages/BanPlayer';
import KickPlayer from '../../../models/socketMessages/KickPlayer';
import GivePlayerItem from '../../../models/socketMessages/GivePlayerItem';
import ReconnectingWebSocket, { CloseEvent } from 'reconnecting-websocket';
import RestartServer from '../../../models/socketMessages/RestartServer';
import handleSocketMessage from './handleSocketMessage';
import GetChat from '../../../models/socketMessages/GetChat';
import GetConsole from '../../../models/socketMessages/GetConsole';
import GetPlayers from '../../../models/socketMessages/GetPlayers';
import GetBans from '../../../models/socketMessages/GetBans';
import { fetchServerStats, monitorServerStats } from '../../serverStats/actionsCreators';
import GetServerInfo from '../../../models/socketMessages/GetServerInfo';
import { logEvent } from '../../analytics/actionsCreators';
import { selectors } from '../../servers';
import { removeServer, setCurrentServer } from '../../servers/servers.actionCreators';

const socketMiddleware = (): Middleware => {
  let socket: ReconnectingWebSocket | null;

  const onOpen = (store: MiddlewareAPI) => () => {
    store.dispatch(beginSocketMonitor.success(socket!));
    store.dispatch(monitorPlayers());
    store.dispatch(monitorServerStats());
    store.dispatch(fetchChat());
    store.dispatch(fetchConsoleLogs());
    store.dispatch(logEvent({
      category: 'Auth',
      action: 'CONNECT',
    }));
  };

  const onClose = (store: MiddlewareAPI) => (event: CloseEvent) => {
    store.dispatch(beginSocketMonitor.failure(new Error(event.code.toString())));
  };

  // the middleware part of this function
  return (store) => (next) => (action) => {
    switch (action.type) {
      case getType(beginSocketMonitor.request):
        if (socket != null) {
          socket?.close();
          socket = null;
        }

        const server = selectors.currentServer(store.getState());

        socket = new ReconnectingWebSocket(`ws://${server!.ip}:${server!.port}/${server!.password}`);

        // websocket handlers
        socket.addEventListener('message', handleSocketMessage(store));
        socket.addEventListener('close', onClose(store));
        socket.addEventListener('open', onOpen(store));

        break;
      case getType(stopSocketMonitor):
        if (socket != null) {
          socket?.close();
        }
        store.dispatch(setCurrentServer(null));
        socket = null;
        break;
      case getType(giveItem):
        const givePlayerItemCommand = new GivePlayerItem(action.payload.steamId, action.payload.item, action.payload.amount);
        socket?.send(givePlayerItemCommand.toString());
        break;
      case getType(sendConsoleMessage):
        const consoleCommand = new SendConsoleCommand(action.payload);
        socket?.send(consoleCommand.toString());
        break;
      case getType(unbanPlayer):
        const unbanCommand = new UnbanPlayer(action.payload);
        socket?.send(unbanCommand.toString());
        break;
      case getType(banPlayer):
        const banCommand = new BanPlayer(action.payload.id, action.payload.reason);
        socket?.send(banCommand.toString());
        break;
      case getType(kickPlayer):
        const kickCommand = new KickPlayer(action.payload.id, action.payload.reason);
        socket?.send(kickCommand.toString());
        break;
      case getType(fetchConsoleLogs):
        const fetchConsoleMessage = new GetConsole();
        socket?.send(fetchConsoleMessage.toString());
        break;
      case getType(fetchChat):
        const getChatCommand = new GetChat();
        socket?.send(getChatCommand.toString());
        break;
      case getType(restartServer):
        const restartCommand = new RestartServer();
        socket?.send(restartCommand.toString());
        break;
      case getType(fetchPlayers):
        const fetchPlayersCommand = new GetPlayers();
        socket?.send(fetchPlayersCommand.toString());
        break;
      case getType(fetchBans):
        const getBansMessage = new GetBans();
        socket?.send(getBansMessage.toString());
        break;
      case getType(fetchServerStats):
        const getServerStats = new GetServerInfo();
        socket?.send(getServerStats.toString());
        break;
      case getType(sendChatMessage):
        const chatCommand = new SendChatMessage(action.payload);
        socket?.send(chatCommand.toString());
        break;
      default: break;
    }
    next(action);
  };
};

export default socketMiddleware();
