import { createSelector } from 'reselect';
import { IReduxState } from '../index';

const serversRoot = (state: IReduxState) => state.servers;
const state = (state: IReduxState) => state;

export const currentServer = createSelector(serversRoot, (serversRoot) => {
  return serversRoot.servers.find(server => server.id === serversRoot.currentServerId);
});

export const addingError = createSelector(serversRoot, (serversRoot) => {
  return serversRoot.addingServerError;
});

export const hasCurrentServer = createSelector(serversRoot, state, (serversRoot, state) => {
  return currentServer(state) != null;
});

export const servers = createSelector(serversRoot, (serversRoot) => {
  return serversRoot.servers;
});

export const isAdding = createSelector(serversRoot, (serversRoot) => {
  return serversRoot.isAddingServer;
});
