import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { routerMiddleware } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as modalReducer } from 'redux-modal'

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import immutableTransform from 'redux-persist-transform-immutable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { getType } from 'typesafe-actions';

import * as serverStatsModule from './serverStats';
import * as routerModule from './router';
import * as sessionModule from './session';
import * as playerModule from './players';
import * as consoleModule from './console';
import * as chatModule from './chat';
import * as analyticsModule from './analytics';
import * as serverModule from './servers';
import socketMiddleware from './session/misc/socketMiddleware';
import { stopSocketMonitor } from './session/actionsCreators';
import isProduction from '../utils/isProduction';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    fork(sessionModule.rootSaga),
    fork(serverStatsModule.rootSaga),
    fork(playerModule.rootSaga),
    fork(routerModule.rootSaga),
    fork(analyticsModule.rootSaga),
    fork(serverModule.rootSaga),
  ]);
}
const appReducer = combineReducers({
  serverStats: serverStatsModule.reducer,
  session: sessionModule.reducer,
  router: routerModule.createRouterReducer(history),
  players: playerModule.reducer,
  console: consoleModule.reducer,
  chat: chatModule.reducer,
  servers: serverModule.reducer,
  toastr: toastrReducer,
  modal: modalReducer,
});

const coreReducer = (state: any, action: any) => {
  if (action.type === getType(stopSocketMonitor)) {
    // Reset all but servers
    state = { servers: state!.servers };
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  transforms: [immutableTransform()],
  storage,
  whitelist: ['servers'],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, coreReducer);

const composed = !isProduction() ? composeWithDevTools(
  applyMiddleware(routerMiddleware(history), sagaMiddleware, socketMiddleware)
) : applyMiddleware(routerMiddleware(history), sagaMiddleware, socketMiddleware);

const rootStore = createStore(
  persistedReducer,
  {},
  composed
);

let persistor = persistStore(rootStore);
sagaMiddleware.run(rootSaga);

export type IReduxState = ReturnType<typeof appReducer>;

export { history, rootStore, persistor };
