import React from 'react';
import classNames from 'classnames';
import NavBar from '../../components/navbar';

export interface Props {
  children: any;
  useFlex?: boolean;
}

export default class Layout extends React.Component<Props> {
  render() {
    const { children, useFlex = false } = this.props;
    return (
      <div className="page">
        <div className="page-main">
          <NavBar />
          <div className={classNames("my-3 my-md-5", { 'd-flex flex-column flex-fill': useFlex })}>
            <div className={classNames("container", { 'd-flex flex-column flex-fill': useFlex })}>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
