import { IReducerState, Server } from './servers.types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './servers.actionCreators';
import { List } from 'immutable';
import { addServer, removeServer, setCurrentServer, updateServer } from './servers.actionCreators';

const initialState: IReducerState = {
  currentServerId: null,
  servers: List<Server>(),
  isAddingServer: false,
  addingServerError: null,
};

const reducer = (state = initialState, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(setCurrentServer):
      return {
        ...state,
        currentServerId: action.payload,
      };
    case getType(addServer.request):
      return {
        ...state,
        addingServerError: null,
        isAddingServer: true,
      };
    case getType(addServer.success):
      return {
        ...state,
        isAddingServer: false,
        servers: state.servers.push(action.payload),
      };
    case getType(addServer.failure):
      return {
        ...state,
        addingServerError: action.payload,
        isAddingServer: false,
      };
    case getType(removeServer):
      const serverIndex = state.servers.findIndex(server => server.id === action.payload);
      if (serverIndex === -1) {
        return state;
      }
      return {
        ...state,
        servers: state.servers.remove(serverIndex),
      };
    case getType(updateServer):
      const updateServerIndex = state.servers.findIndex(server => server.id === state.currentServerId);
      const newServers = state.servers.update(updateServerIndex, (server) => {
        server.name = action.payload;
        return server;
      });
      return {
        ...state,
        servers: newServers,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
