import { createAction } from 'typesafe-actions';
import {
  BAN_PLAYER, FETCH_BANS, FETCH_PLAYERS, GIVE_ITEM,
  KICK_PLAYER,
  MONITOR_PLAYERS,
  SET_BANNED_PLAYERS,
  SET_PLAYERS,
  UNBAN_PLAYER,
} from './players.actions';
import Player from '../../models/Player';
import { PlayerBan } from './types';
import { RustItem } from '../../components/GiveItemModal';

export const monitorPlayers = createAction(MONITOR_PLAYERS)();
export const fetchPlayers = createAction(FETCH_PLAYERS)();
export const fetchBans = createAction(FETCH_BANS)();

export const setPlayers = createAction(SET_PLAYERS)<Player[]>();

export const setBannedPlayers = createAction(SET_BANNED_PLAYERS)<PlayerBan[]>();
export const unbanPlayer = createAction(UNBAN_PLAYER)<string>();
export const banPlayer = createAction(BAN_PLAYER)<{
  id: string,
  reason: string,
}>();

export const kickPlayer = createAction(KICK_PLAYER)<{
  id: string,
  reason: string,
}>();

export const giveItem = createAction(GIVE_ITEM)<{
  steamId: string,
  item: RustItem,
  amount: number,
}>();
