import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { dashboard } from '../../constants/pathnames';
import { IReduxState } from '../../modules';
import { selectors } from '../../modules/session';
import { selectors as serverSelectors } from '../../modules/servers';

export interface PublicRouteProps extends RouteProps {
  isConnected: boolean;
  hasCurrentServer: boolean;
  component: React.ComponentType<RouteComponentProps>;
}

class PublicRoute extends React.Component<PublicRouteProps> {
  render() {
    const { isConnected, hasCurrentServer, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props =>
          (!hasCurrentServer && !isConnected) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: dashboard,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state: IReduxState) => {
  return {
    isConnected: selectors.isConnected(state),
    hasCurrentServer: serverSelectors.hasCurrentServer(state),
  };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(PublicRoute);
