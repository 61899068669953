import { IReducerState } from './types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actionsCreators';

const initialState: IReducerState = {
  connectionStatus: WebSocket.CLOSED,
  connectionError: null,
};

const reducer = (state = initialState, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.beginSocketMonitor.request):
      return {
        ...state,
        connectionError: null,
        connectionStatus: WebSocket.CONNECTING
      };
    case getType(actions.beginSocketMonitor.failure):
      return {
        ...state,
        connectionStatus: WebSocket.CLOSED,
        connectionError: 'Failed to connect to server.',
      };
    case getType(actions.stopSocketMonitor):
      return {
        ...state,
        connectionStatus: WebSocket.CLOSED
      };
    case getType(actions.beginSocketMonitor.success):
      return {
        ...state,
        connectionStatus: WebSocket.OPEN
      };
    default: {
      return state;
    }
  }
};

export default reducer;
