import React from 'react';
import { Card, Form, Button } from 'tabler-react';
import './settings.css';
import Layout from '../layout';
import { Server } from '../../modules/servers/servers.types';

export interface Props {
  removeServer: (id: string) => void;
  currentServer?: Server;
  updateServerName: (name: string) => void;
}

export interface State {
  settingsChanged: boolean;
  serverName: string;
  hasSaved: boolean;
}

export default class Settings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      serverName: props.currentServer!.name,
      settingsChanged: false,
      hasSaved: false,
    };

    this.updateSettings = this.updateSettings.bind(this);
    this.save = this.save.bind(this);
  }

  updateSettings(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.currentTarget;
    this.setState<never>({
      [target.name] : target.value,
      settingsChanged: true,
      hasSaved: false,
    });
  }

  canSave() {
    const { settingsChanged, serverName } = this.state;
    return settingsChanged && !!serverName.trim();
  }

  save() {
    const { updateServerName } = this.props;
    const { serverName } = this.state;

    this.setState({ hasSaved: true });
    updateServerName(serverName);
  }

  render() {
    const { removeServer, currentServer } = this.props;
    const { serverName, hasSaved } = this.state;
    return (
      <Layout>
        <div className="page-header">
          <h1 className="page-title">Settings</h1>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-12 mb-5 mb-lg-0">
            <div className="list-group list-group-transparent mb-0">
              <a
                className="list-group-item list-group-item-action active"
              >
                <span className="icon mr-3">
                  <i className="fe fe-server"></i>
                </span>
                Server
              </a>
            </div>
          </div>
          <div className="col-lg-9 col-md-12">
            <Card>
              <Card.Header>
                <Card.Title>
                  Server Settings
                </Card.Title>
                <Button onClick={this.save} disabled={!this.canSave() || hasSaved} pill color='green' icon={hasSaved ? 'check' : 'save'} className='ml-auto'>
                  {hasSaved ? 'Changes Saved' : 'Save Changes'}
                </Button>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group>
                    <Form.Input onChange={this.updateSettings} name='serverName' label='Server Name' value={serverName} max='120'/>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title className='text-red'>
                  Danger Zone
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                  You can remove the locally saved server by clicking below - you'll need to add it again from the main menu if
                  you want to use this server with <span className='text-red'>Eoka.io</span> again.
                </p>
                <Button onClick={() => removeServer(currentServer!.id)} color='danger'>
                  Remove Server
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Layout>
    );
  }
}
