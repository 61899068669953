import { createSelector } from 'reselect';
import { IReduxState } from '../index';

const session = (state: IReduxState) => state.session;

export const isConnected = createSelector(session, (session) => {
  return session.connectionStatus === WebSocket.OPEN;
});

export const isConnecting = createSelector(session, (session) => {
  return session.connectionStatus === WebSocket.CONNECTING;
});

export const connectionErrorMessage = createSelector(session, (session) => {
  return session.connectionError;
});
