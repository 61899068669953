import React from 'react';
import { ChatMessage } from '../../modules/chat/chat.types';
import ChatMessageItem from '../../features/chat/chatMessageItem';

export interface Props {
  messages: ChatMessage[];
}

export default class PlayerChat extends React.Component<Props> {
  render() {
    const { messages } = this.props;
    return (
      <div className="o-auto pt-5">
        <ul className="list-unstyled list-separated">
          {messages.map((message, index) => (
            <ChatMessageItem
              key={index}
              name={message.name}
              message={message.message}
              color={message.color}
              time={message.time}
              steamID={message.steamId}
            />
          ))}
        </ul>
      </div>
    );
  }
}
