import React from 'react';
import { Icon } from 'react-feather';
import classNames from 'classnames';

export interface Props {
  title: string;
  backgroundImagePath?: string;
  icon: Icon;
  transparent?: boolean,
  className?: string;
}

const EmptyView = ({title, backgroundImagePath, icon: Icon, transparent = false, className}: Props) => (
  <div className={classNames('empty-view', className)} style={!transparent ? { backgroundImage: `url(${require(`../../assets/${backgroundImagePath}`)})`} : {}}>
    <Icon color='white' size={28} />
    <h3 className='text-white mb-0 ml-3'>
      {title}
    </h3>
    {!transparent && (<div className='empty-view-bg' />)}
  </div>
);

export default EmptyView;
