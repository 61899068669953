import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteChildrenProps } from 'react-router';
import { IReduxState } from '../../modules';
import PlayerProfile from './playerProfile.component';
import { selectors as playerSelectors } from '../../modules/players';
import { selectors as chatSelectors } from '../../modules/chat';
import { banPlayer, giveItem, kickPlayer } from '../../modules/players/players.actionCreators';
import { RustItem } from '../../components/GiveItemModal';


export interface Props {}

export type CombinedProps = Props & RouteChildrenProps;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  banPlayer: (id: string, reason: string) => {
    return dispatch(banPlayer({
      id,
      reason
    }));
  },
  kickPlayer: (id: string, reason: string) => {
    return dispatch(kickPlayer({
      id,
      reason
    }));
  },
  giveItem: (steamId: string, item: RustItem, amount: number) => {
    return dispatch(giveItem({
      steamId,
      item,
      amount
    }));
  }
});

const mapStateToProps = (state: IReduxState, ownProps: any) => {
  const playerId = ownProps.match?.params.id;

  return {
    ...ownProps,
    player: playerSelectors.getPlayerForId(state, playerId),
    chatMessages: chatSelectors.chatMessagesForPlayer(state, playerId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfile);


