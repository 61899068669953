import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface Props {
  isOpen: boolean;
  toggle: () => void;
}

class ChangelogModal extends React.Component<Props> {
  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
          Changelog
        </ModalHeader>
        <ModalBody>
          <div>
            <h4>v1.1.1 - 15/01/20</h4>
            <ul>
              <li>Added queued and joined player count to dashboard</li>
              <li>Removed restart server button from dashboard</li>
              <li>Slight styling changes</li>
              <li>28016 is now the default port if no port is entered when adding a server</li>
            </ul>
          </div>
          <div>
            <h4>v1.1.0 - 12/01/20</h4>
            <ul>
              <li>Added the ability to save, edit and delete multiple servers</li>
              <li>Introduced the mailing list to stay up to date with Eoka.io</li>
              <li>Added link to Buy Me A Coffee ❤️</li>
              <li>Fixed an issue where the height on player chat would be 0 on mobile</li>
              <li>Added validation to forms</li>
            </ul>
          </div>
          <div>
            <h4>v1.0.0 - 10/01/20</h4>
            <ul>
              <li>Initial release</li>
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} className='mr-auto' color="purple">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ChangelogModal;
