import React from 'react';
import { Button } from 'tabler-react';
import { List } from 'immutable';
import { Server as ServerIcon } from 'react-feather';

import ServerCard from '../../components/server-card';
import { Server } from '../../modules/servers/servers.types';
import AddServerModal from '../../components/add-server-modal';
import ChangelogModal from '../../components/changelog-modal/changelog-modal';
import EmptyView from '../../components/EmptyView';
import { Collapse } from 'reactstrap';
import MailingListModal from '../../components/mailing-list-modal';

export interface Props {
  servers: List<Server>;
  addServer: (name: string, ip: string, port: string, password: string) => void;
  connectToServer: (id: string) => void;
  isAdding: boolean;
  addError: Error|null;
  showAddServerModal: () => void;
  showMailingListModal: () => void;
}

export interface State {
  changelogOpen: boolean;
  menuOpen: boolean;
}

class ServerList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      changelogOpen: false,
      menuOpen: false,
    };
    this.toggleChangelogModal = this.toggleChangelogModal.bind(this);
    this.connectToServer = this.connectToServer.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleChangelogModal() {
    const { changelogOpen } = this.state;
    this.setState({ changelogOpen: !changelogOpen });
  }

  connectToServer(id: string) {
    const { connectToServer } = this.props;
    connectToServer(id);
  }

  toggleMenu() {
    const { menuOpen } = this.state;
    this.setState({menuOpen: !menuOpen});
  }

  render() {
    const { servers, addServer, isAdding, showAddServerModal, addError, showMailingListModal } = this.props;
    const { changelogOpen, menuOpen } = this.state;
    return (
      <div className='page landing-container'>
        <div className='container pt-8'>
          <div className='row mb-5'>
            <div className='col-12 d-flex flex-column justify-content-between'>
              <div className='d-flex justify-content-between'>
                <h1 className='text-white font-weight-bolder mb-0'>Eoka.io</h1>
                <Button onClick={this.toggleMenu} className='landing-menu-nav-button' color='dark' pill icon='menu'>Menu</Button>
              </div>
              <div className='mt-5 landing-menu d-none d-md-flex'>
                <Button as={'a'} target="_blank" href="https://trello.com/b/RAbXXtHa/eokaio-roadmap" color='indigo' pill icon='target' className='mr-md-3 mb-2 mb-md-0'>
                  View Road Map
                </Button>
                <Button onClick={this.toggleChangelogModal} color='purple' pill icon='info' className='mr-md-3 mb-2 mb-md-0'>
                  View Changelog
                </Button>
                <Button as={'a'} target="_blank" href="https://www.buymeacoffee.com/alexcatch" color='orange' pill icon='dollar-sign' className='mr-md-3 mb-2 mb-md-0'>
                  Support Eoka.io ❤️
                </Button>
                <Button onClick={showMailingListModal} color='blue' pill icon='mail' className='mr-md-3 mb-2 mb-md-0'>
                  Mailing List
                </Button>
                <Button onClick={showAddServerModal} color='success' pill icon='plus' className='mt-lg-0 mt-md-3'>
                  Add Server
                </Button>
              </div>
              <Collapse className='mt-5 landing-menu d-md-none' isOpen={menuOpen}>
                <Button as={'a'} target="_blank" href="https://trello.com/b/RAbXXtHa/eokaio-roadmap" color='indigo' pill icon='target' className='mr-md-3 mb-2 mb-md-0'>
                  View Road Map
                </Button>
                <Button onClick={this.toggleChangelogModal} color='purple' pill icon='info' className='mr-md-3 mb-2 mb-md-0'>
                  View Changelog
                </Button>
                <Button as={'a'} target="_blank" href="https://www.buymeacoffee.com/alexcatch" color='orange' pill icon='dollar-sign' className='mr-md-3 mb-2 mb-md-0'>
                  Support Eoka.io ❤️
                </Button>
                <Button color='blue' onClick={showMailingListModal} pill icon='mail' className='mr-md-3 mb-2 mb-md-0'>
                  Mailing List
                </Button>
                <Button onClick={showAddServerModal} color='success' pill icon='plus'>
                  Add Server
                </Button>
              </Collapse>
            </div>
          </div>
          <div className='row'>
            {servers.map((server) => (
              <div key={server.id} className='col-lg-4 col-md-6 col-sm-12'>
                <ServerCard serverCardClicked={this.connectToServer} {...server} />
              </div>
            ))}
            {servers.isEmpty() && (
              <div className='col-12'>
                <EmptyView className='w-100 border-white mt-3' title="You Don't Have any Saved Servers" icon={ServerIcon} transparent backgroundImagePath={'bg-1.jpg'}/>
              </div>
            )}
          </div>
        </div>
        <AddServerModal error={addError} isAddingServer={isAdding} addServer={addServer} />
        <ChangelogModal isOpen={changelogOpen} toggle={this.toggleChangelogModal} />
        <MailingListModal />
      </div>
    );
  }
}

export default ServerList;
