import { fork, all } from 'redux-saga/effects';
import initAnalytics from './initAnalytics';
import logEvent from './logEvent';
import logPageVisit from './logPageVisit';

export default function* rootSaga() {
  yield all([
    fork(initAnalytics),
    fork(logEvent),
    fork(logPageVisit)
  ])
}
