import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IReduxState } from '../../modules';
import Dashboard from './dashboard.component';

import { selectors as serverStatsSelectors } from '../../modules/serverStats';
import { selectors as playerSelectors } from '../../modules/players';
import { selectors as serverSelectors }  from '../../modules/servers';
import { restartServer } from '../../modules/session/actionsCreators';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  restartServer: () => {
    dispatch(restartServer());
  }
});

const mapStateToProps = (state: IReduxState) => {
  return {
    currentServer: serverSelectors.currentServer(state),
    serverInfo: serverStatsSelectors.serverInformation(state),
    entityData: serverStatsSelectors.entityCountData(state),
    entityCount: serverStatsSelectors.entityCount(state),
    fps: serverStatsSelectors.fps(state),
    framerateData: serverStatsSelectors.framerateData(state),
    memoryData: serverStatsSelectors.memoryData(state),
    formattedMemory: serverStatsSelectors.formattedMemory(state),
    formattedUptime: serverStatsSelectors.formattedUptime(state),
    players: playerSelectors.players(state),
    networkIn: serverStatsSelectors.networkIn(state),
    networkOut: serverStatsSelectors.networkOut(state),
    formattedNetworkIn: serverStatsSelectors.formattedNetworkIn(state),
    formattedNetworkOut: serverStatsSelectors.formattedNetworkOut(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);


