import SocketMessage from './SocketMessage';
import requestTypes from '../../constants/requestIdentifier';

export default class BanPlayer extends SocketMessage {
  identifier = requestTypes.BAN_PLAYER;
  message: string;

  constructor(steamId: string, reason: string) {
    super();
    this.message = `global.ban ${steamId} "${reason}"`;
  }
}
