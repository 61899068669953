import SocketMessage from './SocketMessage';
import requestTypes from '../../constants/requestIdentifier';

export default class UnbanPlayer extends SocketMessage {
  identifier = requestTypes.UNBAN_PLAYER;
  message: string;

  constructor(steamId: string) {
    super();
    this.message = `global.unban ${steamId}`;
  }
}
