import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  ADD_SERVER,
  ADD_SERVER_FAIL,
  ADD_SERVER_SUCCESS,
  REMOVE_SERVER,
  SET_CURRENT_SERVER,
  UPDATE_SERVER,
} from './servers.actions';
import { Server } from './servers.types';

export const addServer = createAsyncAction(
  ADD_SERVER,
  ADD_SERVER_SUCCESS,
  ADD_SERVER_FAIL
)<{
  name: string,
  ip: string,
  port: string,
  password: string,
}, Server, Error>();

export const setCurrentServer = createAction(SET_CURRENT_SERVER)<string|null>();
export const removeServer = createAction(REMOVE_SERVER)<string>();
export const updateServer = createAction(UPDATE_SERVER)<string>();
