import { createSelector } from 'reselect';
import { IReduxState } from '../index';
import { ChatMessage } from './chat.types';

const chatRoot = (state: IReduxState) => state.chat;

export const messages = createSelector(chatRoot, (chat): ChatMessage[] => {
  return chat.chatMessages.toArray();
});

export const chatMessagesForPlayer = (state: IReduxState, playerId: string) => (
  messages(state).filter(message => message.steamId === playerId)
);
