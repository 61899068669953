import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connectModal, InjectedProps } from 'redux-modal';
import { Button, Alert } from 'tabler-react';

import { MAILING_LIST_MODAL } from '../../constants/modalIdentifiers';
import MailingListService from '../../services/MailingListService';

export interface Props {}

export interface State {
  email: string;
  isSubmitting: boolean;
  error: Error|null;
  hasSubscribed: boolean;
}

export type CombinedProps = Props & InjectedProps;

class MailingListModal extends React.Component<CombinedProps, State> {
  static initialState = {
    email: '',
    isSubmitting: false,
    error: null,
    hasSubscribed: false,
  };

  mailingListService = new MailingListService();

  constructor(props: CombinedProps) {
    super(props);
    this.state = MailingListModal.initialState;

    this.close = this.close.bind(this);
    this.updateFormState = this.updateFormState.bind(this);
    this.submit = this.submit.bind(this);
  }

  close() {
    const { handleHide } = this.props;
    this.setState(MailingListModal.initialState);
    handleHide();
  }

  updateFormState(e: React.SyntheticEvent<HTMLInputElement>) {
    this.setState<never>({ [e.currentTarget.name]: e.currentTarget.value });
  }

  async submit(e: React.FormEvent) {
    e.preventDefault();
    const { email } = this.state;
    this.setState({isSubmitting: true, error: null});
    try {
      await this.mailingListService.subscribe(email.trim());
    } catch (error) {
      this.setState({error, isSubmitting: false});
      return;
    }
    this.setState({isSubmitting: false, hasSubscribed: true});
  }

  render() {
    const { show } = this.props;
    const { email, isSubmitting, error, hasSubscribed } = this.state;

    return (
      <Modal isOpen={show} toggle={this.close}>
        <form onSubmit={this.submit}>
          <ModalHeader>
          Mailing List
        </ModalHeader>
          <ModalBody>
            {error && (
              <Alert type='danger'>
                {error?.message}
              </Alert>
            )}
            {hasSubscribed && (
              <Alert type='success'>
                Thanks for subscribing - we'll keep you up to date on things to do with Eoka.io
              </Alert>
            )}
          <div className="row">
            <div className="col-12">
              <p>Subscribe to the Eoka.io mailing list to keep up to date with updates & new releases.</p>
              <div className="form-group">
                <label>Email Address</label>
                <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fe fe-mail" />
                      </span>
                  <input
                    required
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    value={email}
                    onChange={this.updateFormState}
                    name="email"
                  />
                </div>
              </div>
              <small className='text-muted mb-0 mt-1'>We'll only ever use your email to keep you up to date on Eoka.io.</small>
            </div>
          </div>
        </ModalBody>
          <ModalFooter>
          <Button type='button' color="secondary" onClick={this.close}>
            Close
          </Button>
          <Button
            disabled={hasSubscribed}
            icon={hasSubscribed ? 'check' : ''}
            loading={isSubmitting}
            color="blue"
            type='submit'
          >
            {hasSubscribed ? 'Subscribed' : 'Subscribe'}
          </Button>
        </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default connectModal({ name: MAILING_LIST_MODAL, destroyOnHide: false })(MailingListModal)
