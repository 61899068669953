import { takeLatest, call } from 'redux-saga/effects';
import ReactGA from 'react-ga';

import { modulesInitialized } from '../../actionsCreators';

export function* initAnalytics() {
  yield call((code: string) => ReactGA.initialize(code), 'UA-124885272-2');
}

export default function* listenForModuleInit() {
  yield takeLatest(modulesInitialized, initAnalytics);
}

