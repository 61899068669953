export default abstract class SocketMessage {
  identifier!: number;
  message!: string;
  name!: 'Eoka Web';

  toString(): string {
    return JSON.stringify({
        Identifier: this.identifier,
        Message: this.message,
        Name: this.name,
    });
  }
}
