import axios from 'axios';

export interface MailingListResponse {
  success: boolean;
  error?: string;
}

export default class MailingListService {
  httpClient = axios.create({
    baseURL: 'http://api.eoka.io'
  });

  subscribe(email: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpClient.post<MailingListResponse>('/mailing-list', {
          email
        });
        return resolve();
      } catch (err) {
        if (err.response) {
          const { data } = err.response;
          if (data.error === 'email_in_use') {
            return reject(new Error('That email address is already subscribed.'));
          }
        }
        return reject(new Error('An unknown error occurred'));
      }
    })
  }
}
