// Actions
export const MONITOR_PLAYERS = '@@players/MONITOR_PLAYERS';
export const FETCH_PLAYERS = '@@players/FETCH_PLAYERS';
export const FETCH_BANS = '@@players/FETCH_BANS';

export const SET_PLAYERS = '@@players/SET_PLAYERS';

export const SET_BANNED_PLAYERS = '@@players/SET_BANNED_PLAYERS';
export const UNBAN_PLAYER = '@@players/UNBAN_PLAYER';
export const BAN_PLAYER = '@@players/BAN_PLAYER';

export const KICK_PLAYER = '@@players/KICK_PLAYER';

export const GIVE_ITEM = '@@players/GIVE_ITEM';
