import axios from 'axios';
import SteamPlayerResponse from '../models/SteamPlayerResponse';

export default class SteamService {
  httpClient = axios.create({
    baseURL: 'http://api.eoka.io'
  });

  async getPlayerSummary(id: string): Promise<SteamPlayerResponse> {
    const { data } = await this.httpClient.get<SteamPlayerResponse>('/player-profile', {
      params: {
        id
      }
    });
    return data;
  }
}
