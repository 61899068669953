import { takeLatest, call } from 'redux-saga/effects';
import ReactGA from 'react-ga';

import { logEvent } from '../actionsCreators';

export function* performLogEvent(action: ReturnType<typeof logEvent>) {
  yield call(ReactGA.event, action.payload);
}

export default function* listenForAction() {
  yield takeLatest(logEvent, performLogEvent);
}

