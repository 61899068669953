import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connectModal, InjectedProps } from 'redux-modal';
import { Button, Alert } from 'tabler-react';
import isProduction from '../../utils/isProduction';
import { ADD_SERVER_MODAL } from '../../constants/modalIdentifiers';

export interface Props {
  addServer: (name: string, ip: string, port: string, password: string) => void;
  isAddingServer: boolean;
  error?: Error
}

export interface State {
  ip: string;
  port: string;
  password: string;
  name: string;
}

export type CombinedProps = Props & InjectedProps;

class AddServerModal extends React.Component<CombinedProps, State> {
  static initialState = isProduction() ? {
    ip: '',
    port: '',
    password: '',
    name: ''
  } :  {
    ip: '54.36.26.112',
    port: '2087',
    password: 'th8wputb',
    name: 'My Server',
  };
  constructor(props: CombinedProps) {
    super(props);
    this.state = AddServerModal.initialState;

    this.close = this.close.bind(this);
    this.updateFormState = this.updateFormState.bind(this);
    this.addServer = this.addServer.bind(this);
  }

  close() {
    const { handleHide } = this.props;
    handleHide();
  }

  updateFormState(e: React.SyntheticEvent<HTMLInputElement>) {
    this.setState<never>({ [e.currentTarget.name]: e.currentTarget.value });
  }

  addServer(e: React.FormEvent) {
    e.preventDefault();
    const { ip, port, password, name } = this.state;
    const { addServer } = this.props;
    addServer(name.trim(), ip.trim(), port.trim() || '28016', password);
  }

  render() {
    const { show, isAddingServer, error } = this.props;
    const { ip, port, password, name } = this.state;

    return (
      <Modal isOpen={show} toggle={this.close}>
        <form onSubmit={this.addServer}>
          <ModalHeader>
          Add Server
        </ModalHeader>
          <ModalBody>
            {error && (
              <Alert type='danger'>
                {error?.message}
              </Alert>
            )}
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label>Friendly Name</label>
                <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fe fe-edit-2" />
                      </span>
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="name"
                    value={name}
                    onChange={this.updateFormState}
                    name="name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8 pr-0">
              <div className="form-group">
                <label>Server Address</label>
                <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fe fe-server" />
                      </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="127.0.0.1"
                    required
                    value={ip}
                    onChange={this.updateFormState}
                    name="ip"
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Port</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="28016"
                  name="port"
                  value={port}
                  onChange={this.updateFormState}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label>Password</label>
                <div className="input-icon">
                      <span className="input-icon-addon">
                        <i className="fe fe-lock" />
                      </span>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    required
                    value={password}
                    onChange={this.updateFormState}
                    name="password"
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
          <ModalFooter>
          <Button type='button' color="secondary" onClick={this.close}>
            Close
          </Button>
          <Button loading={isAddingServer} color="green" type='submit'>
            Save Server
          </Button>
        </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default connectModal({ name: ADD_SERVER_MODAL, destroyOnHide: false })(AddServerModal)
