import React, { createRef } from 'react';
import Layout from '../layout';

export interface Props {
  logs: string[];
  sendConsoleMessage: (message: string) => void;
}

class Console extends React.Component<Props> {
  shouldScroll = false;

  console = createRef<HTMLDivElement>();
  commandInput = createRef<HTMLInputElement>();
  form = createRef<HTMLFormElement>();

  constructor(props: Props) {
    super(props);
    this.fireCommand = this.fireCommand.bind(this);
  }

  componentDidMount() {
    if (this.console.current) {
      this.console.current.scrollTop = this.console.current.scrollHeight;
    }
    if (this.commandInput.current) {
      this.commandInput.current.focus();
    }
  }

  UNSAFE_componentWillUpdate() {
    if (!this.console.current) {
      return;
    }
    this.shouldScroll =
      this.console.current.scrollTop + this.console.current.clientHeight ===
      this.console.current.scrollHeight;
  }

  componentDidUpdate() {
    if (this.shouldScroll && this.console.current) {
      this.console.current.scrollTop = this.console.current.scrollHeight;
    }
  }

  render() {
    const { logs } = this.props;
    return (
      <Layout useFlex>
        <div className="row d-flex flex-fill flex-column">
          <div className="col-12 d-flex flex-fill flex-column">
            <div
              className="console"
              onClick={() => this.commandInput.current?.focus()}
            >
              <div
                className="pl-5 pr-5 pt-5 pb-0 o-auto console-inner"
                ref={this.console}
              >
                <p className="console-header font-weight-bold">[ Eoka.io console v1.0.0 ]</p>
                {logs.map((log, index) => {
                  return (
                    <p key={index} className="console-text-line mb-2">
                      {log}
                    </p>
                  );
                })}
              </div>
              <form ref={this.form} onSubmit={this.fireCommand}>
                <div className="console-command-line pl-5 pr-5 pt-3 pb-3 d-flex">
                  <label className="mb-0">></label>
                  <input
                    ref={this.commandInput}
                    name="command"
                    className="console-input ml-2"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  fireCommand(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    const { sendConsoleMessage } = this.props;
    const message = e.currentTarget.command.value;
    sendConsoleMessage(message);
    this.form.current?.reset();
  }
}

export default Console;
