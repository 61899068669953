import React from 'react';
import { Redirect, RouteChildrenProps } from 'react-router';
import { Gift } from 'react-feather';

import TabContainer from '../../components/tab-container/index';
import TabMenuItem from '../../components/tab-menu-item/index';
import PlayerChat from '../../components/player-chat';
import Layout from '../layout';
import Player from '../../models/Player';
import { ChatMessage } from '../../modules/chat/chat.types';
import { players } from '../../constants/pathnames';
import ReasonModal from '../../components/reason-modal';
import GiveItemModal, { RustItem } from '../../components/GiveItemModal';
import SteamService from '../../services/SteamService';
import SteamPlayerResponse from '../../models/SteamPlayerResponse';

export interface Props {
  player?: Player;
  chatMessages: ChatMessage[];
  banPlayer: (id: string, reason: string) => void;
  kickPlayer: (id: string, reason: string) => void;
  giveItem: (steamId: string, item: RustItem, amount: number) => void;
}

export interface State {
  currentItem: number;
  error: Error | null;
  steamInformation?: SteamPlayerResponse;
  playerProfile: any;
  kickModalOpen: boolean;
  giveItemModalOpen: boolean;
  banModalOpen: boolean;
}

export type CombinedProps = Props & RouteChildrenProps;

class PlayerProfile extends React.Component<CombinedProps, State> {
  state: State = {
    playerProfile: null,
    currentItem: 0,
    error: null,
    giveItemModalOpen: false,
    kickModalOpen: false,
    banModalOpen: false,
  };

  constructor(props: CombinedProps) {
    super(props);

    if (!props.player) {
      props.history.push(players);
    }

    this.updateActiveComponent = this.updateActiveComponent.bind(this);
    this.toggleKickModal = this.toggleKickModal.bind(this);
    this.toggleGiveItemModal = this.toggleGiveItemModal.bind(this);
    this.toggleBanModal = this.toggleBanModal.bind(this);
    this.kickPlayer = this.kickPlayer.bind(this);
    this.banPlayer = this.banPlayer.bind(this);
    this.givePlayerItem = this.givePlayerItem.bind(this);
  }

  async componentDidMount() {
    await this.fetchSteamInfo();
  }

  toggleKickModal() {
    const { kickModalOpen } = this.state;
    this.setState({ kickModalOpen: !kickModalOpen });
  }

  toggleBanModal() {
    const { banModalOpen } = this.state;
    this.setState({ banModalOpen: !banModalOpen });
  }

  toggleGiveItemModal() {
    const { giveItemModalOpen } = this.state;
    this.setState({ giveItemModalOpen: !giveItemModalOpen });
  }

  async fetchSteamInfo() {
    const { player } = this.props;

    if (!player) {
      return;
    }

    const service = new SteamService();
    const data = await service.getPlayerSummary(player.steamId);
    this.setState({steamInformation: data})
  }

  activeComponent() {
    const { chatMessages } = this.props;
    return <PlayerChat messages={chatMessages} />
  }

  updateActiveComponent(index: number) {
    this.setState({ currentItem: index });
  }

  kickPlayer(reason: string) {
    const { kickPlayer, player } = this.props;
    this.toggleKickModal();
    kickPlayer(player!.steamId, reason);
  }

  banPlayer(reason: string) {
    const { player, banPlayer } = this.props;
    this.toggleBanModal();
    banPlayer(player!.steamId, reason);
  }

  givePlayerItem(item: RustItem, amount: number) {
    const { player, giveItem } = this.props;
    giveItem(player!.steamId, item, amount);
  }

  render() {
    const {
      currentItem,
      kickModalOpen,
      giveItemModalOpen,
      steamInformation,
      banModalOpen
    } = this.state;
    const { player } = this.props;

    if (!player) {
      return <Redirect to={players} />;
    }

    return (
      <Layout useFlex>
        <div className="page-header">
          <h1 className="page-title mr-auto">Player Profile</h1>
          <div className="mt-3 mt-md-0">
            <button
              onClick={this.toggleGiveItemModal}
              className="btn btn-blue ml-auto"
            >
              <Gift className="mr-2 text-white" size={16} />
              Give Item
            </button>
            <button
              onClick={this.toggleBanModal}
              className="btn btn-danger ml-3"
            >
              <i className="fe fe-zap mr-2" />
              Ban Player
            </button>
            <button
              onClick={this.toggleKickModal}
              className="btn btn-warning ml-3"
            >
              <i className="fe fe-log-out mr-2" />
              Kick Player
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-xs-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="p-3 d-flex align-items-center flex-column">
                  <span
                    className="avatar avatar-xxl mb-4"
                    style={steamInformation != null ?                       {
                      backgroundImage: `url('${steamInformation!.avatarUrl}')`,
                    }: {}}
                  />
                  <h4>{player?.displayName}</h4>
                  <div className="d-flex">
                    {steamInformation?.VACBanned && (
                      <div className="tag tag-danger mr-3">
                        VAC bans on record
                        <div
                          className="tag-addon d-flex align-items-center justify-content-center"
                        >
                          <i className="fe fe-slash" />
                        </div>
                      </div>
                    )}
                    {steamInformation?.communityBanned && (
                      <div className="tag tag-warning">
                        Community Banned
                        <div
                          className="tag-addon d-flex align-items-center justify-content-center"
                        >
                          <i className="fe fe-alert-circle" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <table className="table card-table">
                  <tbody>
                    <tr>
                      <td>
                        <i className="fe fe-user text-muted"></i>
                      </td>
                      <td>Steam ID</td>
                      <td className="text-right">
                        <a target='_blank' rel="noopener noreferrer" href={steamInformation?.profileUrl} className="text-red">{player?.steamId}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fe fe-activity text-muted"></i>
                      </td>
                      <td>Health</td>
                      <td className="text-right">
                        <span className="text-muted">
                          {player?.getFormattedHealth()} / 100
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fe fe-clock text-muted"></i>
                      </td>
                      <td>Play Time</td>
                      <td className="text-right">
                        <span className="text-muted">
                          {player?.getFormattedConnectedTime()}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fe fe-trending-up text-muted"></i>
                      </td>
                      <td>Ping</td>
                      <td className="text-right">
                        <span className="text-muted">{player?.ping}ms</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fe fe-cloud text-muted"></i>
                      </td>
                      <td>IP</td>
                      <td className="text-right">
                        <span className="text-muted">{player?.address}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-6 col-xs-12 d-flex flex-column flex-fill">
            <TabContainer
              style={{
                flex: "1 1 auto",
                height: "0px",
                minHeight: "500px"
              }}
              menuItems={[
                <TabMenuItem
                  key={0}
                  title="Chat"
                  onClick={() => this.updateActiveComponent(0)}
                  active={currentItem === 0}
                />,
              ]}
            >
              {this.activeComponent()}
            </TabContainer>
          </div>
        </div>
        <ReasonModal
          isOpen={kickModalOpen}
          toggle={this.toggleKickModal}
          title="Kick Player"
          description={`Are you sure you want to kick "${player.displayName}" from the server?`}
          placeholder={'Enter kick reason'}
          actionButtonTitle="Kick Player"
          onActionFired={this.kickPlayer}
        />
        <ReasonModal
          isOpen={banModalOpen}
          toggle={this.toggleBanModal}
          title="Ban Player"
          description={`Are you sure you want to ban "${player.displayName}" from the server?`}
          placeholder={'Enter ban reason'}
          actionButtonTitle="Ban Player"
          onActionFired={this.banPlayer}
        />
        <GiveItemModal
          giveItem={this.givePlayerItem}
          isOpen={giveItemModalOpen}
          toggle={this.toggleGiveItemModal}
        />
      </Layout>
    );
  }
}

export default PlayerProfile;
