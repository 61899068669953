import humanizeDuration from 'humanize-duration';

export default class Player {
  displayName: string;
  address: string;
  steamId: string;
  health: number;
  connectedSeconds: number;
  ping: number;

  constructor(
    displayName: string,
    address: string,
    steamId: string,
    health: number,
    connectedSeconds: number,
    ping: number
  ) {
    this.displayName = displayName;
    this.address = address;
    this.steamId = steamId;
    this.health = health;
    this.connectedSeconds = connectedSeconds;
    this.ping = ping;
  }

  getFormattedConnectedTime(): string {
    return humanizeDuration(this.connectedSeconds * 1000, { largest: 2 });
  }

  getFormattedHealth(): string {
    return Math.round(this.health).toString();
  }

}
