import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IReduxState } from '../../modules';
import App from './index';
import { modulesInitialized } from '../../modules/actionsCreators';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  modulesInit: () => {
    dispatch(modulesInitialized());
  }
});

const mapStateToProps = (state: IReduxState) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
