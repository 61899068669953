import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { NavLink, RouteChildrenProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Navbar as BootstrapNavbar, NavbarToggler, Collapse } from 'reactstrap';

import * as paths from '../../constants/pathnames';
import { IReduxState } from '../../modules';
import { selectors } from '../../modules/session';
import { selectors as serverSelectors } from '../../modules/servers';
import { stopSocketMonitor } from '../../modules/session/actionsCreators';
import ConnectionNavItem from '../ConnectionNavItem';
import { servers } from '../../modules/servers/servers.selectors';

interface NavbarProps {
  connected: boolean;
  isConnecting: boolean;
  hasCurrentServer: boolean;
  disconnect: () => void;
}

export type ComposedProps = RouteChildrenProps & NavbarProps;

const Navbar = ({ connected, disconnect, hasCurrentServer }: ComposedProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const toggleSidebar = () => {
    // @ts-ignore
    window.drift.on('ready', function(api: any) {
      api.sidebar.open();
      // @ts-ignore
    })
  };

  return (
    <Fragment>
      <BootstrapNavbar className="header py-3">
        <div className="container">
          <div className="d-flex align-items-center flex-fill">
            <i
              className="fe fe-crosshair text-danger mr-1"
              style={{ fontSize: '18px' }}
            />
            <div className="header-brand text-white mr-auto">Eoka</div>
              <div className="d-flex order-lg-2">
                <div className="nav-item d-flex">
                  <button
                    className="btn btn-sm text-white btn-danger btn-pill"
                    onClick={disconnect}
                  >
                    Disconnect
                  </button>
                </div>
              </div>
            <NavbarToggler
              onClick={toggleNavbar}
              className="header-toggler d-lg-none ml-3 ml-lg-0"
            >
              <span className="header-toggler-icon" />
            </NavbarToggler>
          </div>
        </div>
      </BootstrapNavbar>
      {(hasCurrentServer || connected) && (
        <Collapse
          isOpen={!collapsed}
          className="header d-lg-flex p-0 header-bottom"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg order-lg-first">
                <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                  <li className="nav-item">
                    <NavLink
                      to={paths.dashboard}
                      className="nav-link"
                      activeClassName="border-danger text-danger"
                    >
                      <i className="fe fe-home" />
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={paths.players}
                      className="nav-link"
                      activeClassName="border-danger text-danger"
                    >
                      <i className="fe fe-users" /> Players
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={paths.chat}
                      className="nav-link"
                      activeClassName="border-danger text-danger"
                    >
                      <i className="fe fe-message-square" /> Chat
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={paths.console}
                      className="nav-link"
                      activeClassName="border-danger text-danger"
                    >
                      <i className="fe fe-code"></i> Console
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={paths.settings}
                      className="nav-link"
                      activeClassName="border-danger text-danger"
                    >
                      <i className="fe fe-settings"></i> Settings
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={toggleSidebar}
                    >
                      <i className="fe fe-help-circle"></i> Give Feedback
                    </a>
                  </li>
                  <ConnectionNavItem />
                </ul>
              </div>
            </div>
          </div>
        </Collapse>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  disconnect: () => dispatch(stopSocketMonitor()),
});

const mapStateToProps = (state: IReduxState) => {
  return {
    connected: selectors.isConnected(state),
    isConnecting: selectors.isConnecting(state),
    hasCurrentServer: serverSelectors.hasCurrentServer(state),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
