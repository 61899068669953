import JSONBig from "json-bigint";
import { SocketResponse } from '../models/socketResponses/SocketResponse';

export default (event: MessageEvent): SocketResponse<any> => {
  let parsedMessage = JSONBig.parse(event.data);

  let innerMessage = parsedMessage.Message;

  try {
    innerMessage = JSONBig.parse(parsedMessage.Message);
  } catch {}

  parsedMessage.Message = innerMessage;

  return parsedMessage;
}
