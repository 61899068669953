import { createSelector } from 'reselect';
import { IReduxState } from '../index';

const playersRoot = (state: IReduxState) => state.players;

export const players = createSelector(playersRoot, (playerState) => {
  return playerState.players;
});

export const bannedPlayers = createSelector(playersRoot, (playerState) => {
  return playerState.playerBans;
});

export const getPlayerForId = (state: IReduxState, id: string) => {
  return players(state).find(player => player.steamId === id);
};
