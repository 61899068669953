import { createSelector } from 'reselect';
import humanizeDuration from 'humanize-duration';
import { IReduxState } from '../index';
import formatNumber from '../../utils/formatNumber';
import formatBytes from '../../utils/formatBytes';
import { ServerInfo } from './types';

const serverStatsRoot = (state: IReduxState) => state.serverStats;

export const serverInformation = createSelector(serverStatsRoot, (stats): ServerInfo => {
  return {
    hostName: stats.hostName,
    playerCount: stats.playerCount,
    playersQueued: stats.playersQueued,
    playersConnecting: stats.playersConnecting,
    maxPlayers: stats.maxPlayers,
    uptime: stats.uptime,
  }
});

export const formattedUptime = createSelector(serverStatsRoot, (stats) => {
  return humanizeDuration(stats.uptime * 1000, { largest: 2 });
});

export const entityCount = createSelector(serverStatsRoot, (stats) => {
  const count = stats.entityCount.get(-1)?.count || 0;
  return formatNumber(count);
});

export const entityCountData = createSelector(serverStatsRoot, (stats) => {
  return stats.entityCount.map((item) => ({
    name: item.name,
    key: item.count,
  })).toArray();
});

export const framerateData = createSelector(serverStatsRoot, (stats) => {
  return stats.framerate.map((item) => ({
    name: item.name,
    key: item.fps,
  })).toArray();
});

export const fps = createSelector(serverStatsRoot, (stats) => {
  return stats.framerate.get(-1)?.fps.toString() || '0';
});

export const memoryData = createSelector(serverStatsRoot, (stats) => {
  return stats.memory.map((item) => ({
    name: item.name,
    key: item.memory,
  })).toArray();
});

export const formattedMemory = createSelector(serverStatsRoot, (stats) => {
  const count = stats.memory.get(-1)?.memory || 0;
  return formatBytes(count);
});

export const networkIn = createSelector(serverStatsRoot, (stats) => {
  return stats.networkIn.map((item) => ({
    name: item.name,
    key: item.in,
  })).toArray();
});

export const formattedNetworkIn = createSelector(serverStatsRoot, (stats) => {
  const count = stats.networkIn.get(-1)?.in || 0;
  return formatBytes(count);
});

export const networkOut = createSelector(serverStatsRoot, (stats) => {
  return stats.networkOut.map((item) => ({
    name: item.name,
    key: item.out,
  })).toArray();
});

export const formattedNetworkOut = createSelector(serverStatsRoot, (stats) => {
  const count = stats.networkOut.get(-1)?.out || 0;
  return formatBytes(count);
});

