import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Users, UserX } from 'react-feather';

import PlayersTable from '../../components/players-table';
import Layout from '../layout';
import Player from '../../models/Player';
import { PlayerBan } from '../../modules/players/types';
import PlayersBanTable from '../../components/players-ban-table';
import EmptyView from '../../components/EmptyView';

enum TypeFilter {
  Active,
  Banned,
}

export interface Props {
  players: Player[];
  bannedPlayers: PlayerBan[];
  unbanPlayer: (steamId: string) => void;
}

export interface State {
  searchTerm: string;
  activeFilter: TypeFilter;
}

export type CombinedProps = Props & RouteChildrenProps;

class Players extends React.Component<CombinedProps> {
  state = {
    activeFilter: TypeFilter.Active,
    searchTerm: '',
  };

  constructor(props: CombinedProps) {
    super(props);
    this.searchUsers = this.searchUsers.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
  }

  searchUsers(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const searchText = e.target.value;
    this.setState({ searchTerm: searchText });
  }

  filterPlayers() {
    const { players } = this.props;
    const { searchTerm } = this.state;
    return players.filter(player => {
      return (
        player.displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        player.steamId.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  filterBans() {
    const { bannedPlayers } = this.props;
    const { searchTerm } = this.state;
    return bannedPlayers.filter(bannedPlayer => {
      return (
        bannedPlayer.username
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        bannedPlayer.steamID.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  renderTable() {
    const { unbanPlayer } = this.props;
    const { activeFilter } = this.state;

    if (activeFilter === TypeFilter.Active) {
      const filteredPlayers = this.filterPlayers();
      if (filteredPlayers.length === 0) {
        return (
          <EmptyView icon={Users} title={'No Connected Players'} backgroundImagePath={'default-bg.jpg'} />
        )
      }
      return <PlayersTable players={this.filterPlayers()} />;
    }

    const bans = this.filterBans();
    if (bans.length === 0) {
      return (
        <EmptyView icon={UserX} title={'No Banned Players'} backgroundImagePath={'bg-1.jpg'} />
      )
    }
    return (
      <PlayersBanTable unbanPlayer={unbanPlayer} players={bans} />
    );
  }

  updateFilter(newFilter: TypeFilter) {
    this.setState({ activeFilter: newFilter });
  }

  render() {
    const { activeFilter } = this.state;
    return (
      <Layout>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <form className="row gutters-xs">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="searchInput"
                    onChange={this.searchUsers}
                    placeholder="Search for a player..."
                  />
                </div>
                <div className="col-auto">
                  <div className="selectgroup">
                    <label className="selectgroup-item">
                      <input
                        onChange={() => this.updateFilter(TypeFilter.Active)}
                        type="radio"
                        name="value"
                        value="50"
                        className="selectgroup-input"
                        checked={activeFilter === TypeFilter.Active}
                      />
                      <span className="selectgroup-button">Online</span>
                    </label>
                    <label className="selectgroup-item">
                      <input
                        onChange={() => this.updateFilter(TypeFilter.Banned)}
                        type="radio"
                        name="value"
                        value="100"
                        className="selectgroup-input"
                        checked={activeFilter === TypeFilter.Banned}
                      />
                      <span className="selectgroup-button">Banned</span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">{this.renderTable()}</div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Players;
