import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {
  root,
  players,
  dashboard,
  chat,
  console,
  playerProfile, settings,
} from '../../constants/pathnames';

import Dashboard from '../dashboard/dashboard.container';
import Players from '../players/players.container';
import Chat from '../chat/chat.container';
import Console from '../console/console.container';
import PlayerProfile from '../player-profile/playerProfile.container';

import ProtectedRoute from '../../components/protected-route';
import PublicRoute from '../../components/public-route';
import ServerList from '../server-list/server-list.container';
import Settings from '../settings/settings.container';

export interface Props {
  modulesInit: () => void;
}

class App extends React.Component<Props> {
  componentDidMount() {
    const { modulesInit } = this.props;
    modulesInit();
  }

  render() {
    return (
      <Switch>
        <PublicRoute exact path={root} component={ServerList} />
        <ProtectedRoute exact path={dashboard} component={Dashboard} />
        <ProtectedRoute exact path={players} component={Players} />
        <ProtectedRoute exact path={chat} component={Chat} />
        <ProtectedRoute exact path={console} component={Console} />
        <ProtectedRoute exact path={playerProfile} component={PlayerProfile} />
        <ProtectedRoute exact path={settings} component={Settings} />
        <Redirect to={root} />
      </Switch>
    );
  }
}

export default App;
