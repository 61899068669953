import React from 'react';
import {
  ResponsiveContainer,
  LineChart as RechartLineChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  TooltipProps,
} from 'recharts';
import colors from '../../utils/colors';

const CustomTooltip = (props: TooltipProps) => (
  <div className="card">
    <div className="card-body pt-2 pb-2">
      <div
        style={{
          color:
            props.payload && props.payload[0] ? props.payload[0].color : '',
        }}
      >
        {props.label}
      </div>
      <div>{props.payload && props.payload[0] && props.payload[0].value}</div>
    </div>
  </div>
);
const LineChart = ({
  fixedHeight = true,
  color = 'green',
  data,
}: {
  fixedHeight?: boolean;
  color?: string;
  data?: any;
}) => (
  <ResponsiveContainer minHeight={100} height={fixedHeight ? 100 : '100%'}>
    <RechartLineChart
      data={data}
      margin={{
        top: 10,
        left: 0,
        right: 0,
        bottom: 10,
      }}
    >
      <Line type="monotone" dot={false} dataKey="key" stroke={colors[color]} />
      <XAxis hide domain={['dataMin', 'dataMax']} dataKey="name" />
      <YAxis hide domain={['dataMin', 'dataMax']} />
      <Tooltip
        cursor={false}
        content={<CustomTooltip />}
        contentStyle={{ borderRadius: 11 }}
      />
    </RechartLineChart>
  </ResponsiveContainer>
);

export default LineChart;
