import SocketMessage from './SocketMessage';
import requestTypes from '../../constants/requestIdentifier';

export default class SendConsoleCommand extends SocketMessage {
  identifier = requestTypes.SEND_CONSOLE_MESSAGE;
  message: string;

  constructor(message: string) {
    super();
    this.message = message;
  }
}
