import { List } from 'immutable';
import { IReducerState } from './console.types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './console.actionCreators';

const initialState: IReducerState = {
  consoleMessages: List<string>(),
};

const reducer = (state = initialState, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.setConsoleLogs):
      return {
        ...state,
        consoleMessages: List<string>(action.payload)
      };
    case getType(actions.sendConsoleMessage):
    case getType(actions.appendConsoleLog):
      return {
        ...state,
        consoleMessages: state.consoleMessages.push(action.payload)
      };
    default: return state;
  }
};

export default reducer;
