import SocketMessage from './SocketMessage';
import requestTypes from '../../constants/requestIdentifier';
import { RustItem } from '../../components/GiveItemModal';

export default class GivePlayerItem extends SocketMessage {
  identifier = requestTypes.GIVE_PLAYER_ITEM;
  message: string;

  constructor(steamId: string, item: RustItem, amount: number) {
    super();
    this.message = `inventory.giveto ${steamId} ${item.item_short_code} ${amount}`;
  }
}
