import { takeLatest, take, put} from 'redux-saga/effects';
import uuid from 'uuid/v1';
import { addServer } from '../servers.actionCreators';
import addServerSocketChannel from '../misc/addServerSocketChannel';
import { hide } from 'redux-modal';
import { ADD_SERVER_MODAL } from '../../../constants/modalIdentifiers';

export function* beginAddServer(action: ReturnType<typeof addServer.request>) {
  // Attempt a one time connection through a socket
  const socket = new WebSocket(`ws://${action.payload.ip}:${action.payload.port}/${action.payload.password}`);
  const channel = addServerSocketChannel(socket);

  try {
    const imageHeader = yield take(channel);
    yield put(addServer.success({
      id: uuid(),
      name: action.payload.name,
      ip: action.payload.ip,
      port: action.payload.port,
      password: action.payload.password,
      backgroundUrl: imageHeader
    }));
    yield put(hide(ADD_SERVER_MODAL));
  } catch (err) {
    yield put(addServer.failure(err));
  }
}

export default function* listenForAddServer() {
  yield takeLatest(addServer.request, beginAddServer);
}
