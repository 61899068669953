import React from 'react';

import PlayersTableRow from '../players-table-row';

const PlayersTable = ({ players }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover table-outline table-vcenter text-nowrap card-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Steam ID</th>
            <th>Health</th>
            <th>Play Time</th>
            <th>Ping</th>
          </tr>
        </thead>
        <tbody>
          {players.map(player => (
            <PlayersTableRow key={player.steamId} player={player} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayersTable;
