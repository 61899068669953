import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ReasonModal = ({
  isOpen,
  toggle,
  title,
  description,
  placeholder,
  actionButtonTitle,
  onActionFired,
}: {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  description: string;
  placeholder: string;
  actionButtonTitle: string;
  onActionFired: (reason: string) => void;
}) => {
  const [text, setText] = useState('');

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <p>{description}</p>
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          name="reason"
          title={text}
          onChange={e => setText(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="red" onClick={() => onActionFired(text)}>
          {actionButtonTitle}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReasonModal;
