import React from 'react';
import bgImage from '../../assets/default-bg.jpg';

export interface Props {
  id: string;
  name: string;
  ip: string;
  port: string;
  backgroundUrl: string;
  serverCardClicked: (id: string) => void;
}

const ServerCard = ({id, name, ip, port, backgroundUrl, serverCardClicked}: Props) => (
  <div className='card server-card o-hidden border-0' onClick={() => serverCardClicked(id)}>
    <div className='card-body d-flex align-items-end'>
      <img className='server-card-img' src={backgroundUrl || bgImage} />
      <div className='server-card-bg' />
      <div className='server-card-content'>
        <h2 className='text-white text-overflow-ellipsis mb-0 font-weight-bolder'>
          {name}
        </h2>
        <p className='mb-0 text-red'>{ip}:{port}</p>
      </div>
    </div>
  </div>
);

export default ServerCard;
