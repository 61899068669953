enum RequestIdentifier {
  RECEIVE_CHAT_MESSAGE = -1,
  RECEIVE_CONSOLE_MESSAGE= 0,
  GET_PLAYERS = 1001,
  GET_SERVER_INFO = 1005,
  GET_CONSOLE_LOGS = 1006,
  KICK_ALL = 1012,
  SEND_CONSOLE_MESSAGE = 1014,
  SEND_CHAT_MESSAGE = 1015,
  GET_BANS = 1016,
  UNBAN_PLAYER = 1017,
  BAN_PLAYER = 1018,
  KICK_PLAYER = 1019,
  GET_CHAT = 1020,
  GIVE_PLAYER_ITEM = 1021,
  RESTART_SERVER = 1022,
  GET_HEADER_IMAGE = 1023,
}

export default RequestIdentifier;
