import { IReducerState } from './types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './players.actionCreators';

const initialState: IReducerState = {
  players: [],
  playerBans: [],
};

const reducer = (state = initialState, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.setPlayers):
      return {
        ...state,
        players: action.payload
      };
    case getType(actions.setBannedPlayers):
      return {
        ...state,
        playerBans: action.payload,
      };
    case getType(actions.unbanPlayer):
      // Find the user and remove from bans list.
      const unbanIndex = state.playerBans.findIndex(ban => ban.steamID === action.payload);
      if (unbanIndex === -1) {
        return state;
      }
      const newBans = state.playerBans.slice(unbanIndex, unbanIndex);
      return {
        ...state,
        playerBans: newBans,
      };
    case getType(actions.kickPlayer):
    case getType(actions.banPlayer):
      // Find the user and remove from bans list.
      const banIndex = state.players.findIndex(ban => ban.steamId === action.payload.id);
      if (banIndex === -1) {
        return state;
      }
      const newPlayers = state.players.slice(banIndex, banIndex);
      return {
        ...state,
        players: newPlayers,
      };
    default: return state;
  }
};

export default reducer;
