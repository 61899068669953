import React from 'react';
import { PlayerBan } from '../../modules/players/types';

const PlayerBanTableRow = ({
  player,
  unbanPlayer,
}: {
  player: PlayerBan;
  unbanPlayer: (steamID: string) => void;
}) => (
  <tr key={player.steamID}>
    <td>
      <div className="text-red">{player.username}</div>
    </td>
    <td>
      <div>{player.steamID}</div>
    </td>
    <td>
      <div>{player.reason}</div>
    </td>
    <td className="w-1">
      <button
        onClick={() => unbanPlayer(player.steamID)}
        className="btn btn-pill btn-success"
      >
        <i className="fe fe-check mr-2" />
        Unban Player
      </button>
    </td>
  </tr>
);

export default PlayerBanTableRow;
