import { takeLatest, select, put } from 'redux-saga/effects';

import { modulesInitialized } from '../../actionsCreators';
import { beginSocketMonitor } from '../actionsCreators';
import { selectors } from '../../servers';

/**
 * Ensure
 */
export function* ensureServerConnection() {
  if (yield select(selectors.hasCurrentServer)) {
    const server = yield select(selectors.currentServer)!;
    yield put(beginSocketMonitor.request(server.id));
  }
}

export default function* listenSocketMonitor() {
  yield takeLatest(modulesInitialized, ensureServerConnection);
}

