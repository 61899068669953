import React from 'react';

import { PlayerBan } from '../../modules/players/types';
import PlayerBanTableRow from '../players-ban-table-row';

const PlayersBanTable = ({
  players,
  unbanPlayer,
}: {
  players: PlayerBan[];
  unbanPlayer: (steamId: string) => void;
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover table-outline table-vcenter text-nowrap card-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Steam ID</th>
            <th>Reason</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {players.map(player => (
            <PlayerBanTableRow unbanPlayer={unbanPlayer} player={player} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayersBanTable;
